$(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    var b = document.getElementById('background');
    var n = $("#background");

    if (n.length > 0) {
        var r = n[0].getContext("2d");

        b.width = window.innerWidth;
        b.height = window.innerHeight;

        function drawBackground() {
            var e, t, i = [],
                s, o, u = 10;

            s = window.innerWidth;
            o = window.innerHeight;
            r.strokeStyle = "#c9c9c9";
            r.lineWidth = .1;

            for (e = 0; e < 4 * u; e += 1) {
                i.push({
                    x: Math.random() * s,
                    y: Math.random() * o,
                    vx: Math.random() * 4 - 2,
                    vy: Math.random() * 4 - 2
                })
            }

            var a = 0;

            setInterval(function () {
                if (a <= 3e4) {
                    for (t = 0; t < u; t += 1) {
                        r.beginPath();
                        r.moveTo(i[t * 4].x, i[t * 4].y);
                        r.bezierCurveTo(i[t * 4 + 1].x, i[t * 4 + 1].y, i[t * 4 + 2].x, i[t * 4 + 2].y, i[t * 4 + 3].x, i[t * 4 + 3].y);
                        r.stroke()
                    }
                    for (e = 0; e < i.length; e += 1) {
                        i[e].y += i[e].vx;
                        i[e].x += i[e].vy;
                        a++
                    }
                } else {
                    clearInterval(setInterval)
                }
            }, 1e3 / 24);
        }

        drawBackground();
    }

    var shouldAppendCatalogueLink = true;

    $('#homepage_animation').on('timeupdate', function () {
        var breakpoint = 10;

        if (this.currentTime > breakpoint && this.currentTime >= this.duration) {
            this.currentTime = breakpoint;
            this.play();
        }
    });

    $('.navbar-toggle').click(function () {
        $(this).toggleClass('open');
    }).sidr({
        source: '.navbar-collapse',
        side: 'right',
        speed: 500,
        onOpen: function () {
            if (shouldAppendCatalogueLink) {
                $('.sidr-class-navbar-nav.sidr-class-language-change').append('<li class="catalogue">' + $('.sidr-class-catalogue').detach().html() + '</li>');
            }
            
            shouldAppendCatalogueLink = false;
        }
    });

    var updateScroll = function () {
        if (typeof scrollApi !== 'undefined') {
            scrollApi.reinitialise();

            var paragraph = scrollApi.getContentPane().find('p');

            if (scrollApi.getIsScrollableV()) {
                paragraph.css('padding-right', '10px');
            } else {
                paragraph.css('padding-right', '0');
            }
        }
    };

    /**
     * Google Maps
     */
    if ($('#map').length > 0) {
        var map;

        function initMap() {
            map = new google.maps.Map(document.getElementById('map'), {
                center: new google.maps.LatLng(contactLocation[0], contactLocation[1]),
                scrollwheel: false,
                zoom: 15
            });

            var marker = new google.maps.Marker({
                map: map,
                position: new google.maps.LatLng(contactLocation[0], contactLocation[1]),
                icon: '/public/assets/images/map-pin.png'
            });
        }

        google.maps.event.addDomListener(window, 'load', initMap);
    }

    /**
     * Carousel'ler ortak olarak tanımlanıyor, kendi özellikleri
     * her carousel'in içerisinde data-slick attribute'i ile veriliyor.
     */
    $('.carousel, .carousel-navigation').slick({
        customPaging: function (slider, i) {
            return '<div class="carousel-dot"><button type="button" data-role="none" role="button" aria-required="false" tabindex="0">' + (i + 1) + '</button></div>';
        }
    });

    /**
     * Navigasyonu olan carousel'ler için ana carousel'den uzantı
     * çekilerek navigation item'ı oluşturuluyor.
     */
    var subpageCarousel = $('.subpage_carousel .carousel');

    subpageCarousel.find('figure.carousel-item').each(function (k, v) {
        var extension = $(v).data('extension');
        $('.subpage_carousel .carousel-navigation').slick('slickAdd', '<div class="carousel-navigation-item"><img src="/image/' + extension + '?w=200&h=160&fit=crop" alt=""></div>')
    });

    $('.subpage_carousel .carousel-navigation').on('init', function () {
        $('.slick-dots').find('.slick-active').find('.carousel-dot').circleProgress('value', 1);
    }).on('beforeChange', function () {
        $('.slick-dots').find('.slick-active').find('.carousel-dot').circleProgress('value', 0);
    }).on('afterChange', function (event, slick, currentSlide) {
        $('.slick-dots').find('.slick-active').find('.carousel-dot').circleProgress('value', 1);
    });

    /**
     * Yazı alanları sayfadan yüksekse scroll paneli gösterilir.
     */
    var scrollApi;
    var scrollableSelector = $('.scrollable');

    if (scrollableSelector.length > 0) {
        var scrollPanel = scrollableSelector.jScrollPane({
            autoReinitialise: true
        });
        scrollApi = scrollPanel.data('jsp');

        $(window).resize(function () {
            updateScroll();
        });
    }

    $('.back-button').circleProgress({
        value: 1.0,
        size: 40,
        thickness: 1,
        emptyFill: 'rgba(0, 0, 0, 0)',
        fill: {
            color: '#ffffff'
        }
    });

    $('.carousel-dot').circleProgress({
        value: 0,
        size: 18,
        thickness: 1,
        emptyFill: 'rgba(0, 0, 0, 0)',
        fill: {
            color: '#fd4f00'
        }
    });

    $('form#address_frame').formValidation({
        framework: 'bootstrap',
        err: {
            container: '.validation-messages'
        }
    });

    $('form#hr').formValidation({
        framework: 'bootstrap',
        err: {
            container: '.validation-messages'
        },
        fields: {
            // FIXME: emailAddress çalışmıyor
            'data[email]': {
                validators: {
                    emailAddress: {
                        message: 'Geçerli bir mail adresi girmeniz gerekmektedir.'
                    },
                    notEmpty: {
                        message: 'Mail adresinizi girmeniz gerekmektedir.'
                    }
                }
            },
            'data[marital-status]': {
                validators: {
                    callback: {
                        message: 'Geçerli bir şık seçmelisiniz',
                        callback: function (value, validator) {
                            return validator.getFieldElements('data[marital-status]').val() != ''
                        }
                    }
                }
            },
            'data[drivers-license]': {
                validators: {
                    callback: {
                        message: 'Geçerli bir şık seçmelisiniz',
                        callback: function (value, validator) {
                            return validator.getFieldElements('data[drivers-license]').val() != ''
                        }
                    }
                }
            },
            'data[military]': {
                validators: {
                    callback: {
                        message: 'Geçerli bir şık seçmelisiniz',
                        callback: function (value, validator) {
                            return validator.getFieldElements('data[military]').val() != ''
                        }
                    }
                }
            }
        }
    });

    $('.form-templates.hr select').select2({
        minimumResultsForSearch: Infinity
    }).change(function (e) {
        // http://formvalidation.io/examples/select2/
        $('form#hr').formValidation('revalidateField', $(e.currentTarget).attr('name'));
    }).end();

    $('form').on('success.form.fv', function (e) {
        e.preventDefault();

        var self = $(this);

        $.ajax({
            type: 'POST',
            url: self.attr('action'),
            data: self.serialize(),
            success: function () {
                toastr.success(self.data('success-message'), self.data('title'));
                $('.form-templates.hr select').select2('val', '');
                $('form')[0].reset();
            },
            error: function () {
                toastr.error('An error occured when trying to submit the form.', self.data('title'));
            }
        });
    });

    var lightGallery = $('.carousel.zoomable').lightGallery({
        selector: '.carousel-item',
        download: false,
        nextHtml: '<button class="slick-next slick-arrow" role="button">Next</button>',
        prevHtml: '<button class="slick-prev slick-arrow" role="button">Previous</button>'
    });

    lightGallery.on('onAfterSlide.lg', function(event, index) {
        $('.carousel.zoomable').slick('slickGoTo', index + 1);
    });

    $(window).trigger('resize');
});

var $lg;
var legoModule = angular.module('Legoraf', ['slickCarousel', 'ngSanitize']);
legoModule.directive('lightGallery', function() {
    return {
        restrict: 'A',
        link: function(scope, element) {
            if (scope.$last) {
                $lg = element.parent().lightGallery({
                    selector: '.item',
                    download: false,
                    nextHtml: '<button class="slick-next slick-arrow" role="button">Next</button>',
                    prevHtml: '<button class="slick-prev slick-arrow" role="button">Previous</button>'
                });

                $lg.on('onBeforePrevSlide.lg',function() {
                    if (scope.$parent != null) {
                        scope.$parent.carouselNavigate('prev');
                    }
                });

                $lg.on('onBeforeNextSlide.lg',function() {
                    if (scope.$parent != null) {
                        scope.$parent.carouselNavigate('next');
                    }
                });
            }
        }
    };
});
legoModule.controller('DynamicListController', ['$scope', '$timeout', function($scope, $timeout) {
    $scope.submenus = submenuData || false;
    $scope.superSubmenus = false;
    $scope.activeSuperSubmenu = false;
    $scope.animateGallery = true;

    $scope.navigationCarouselConfig = {
        slidesToShow: 3,
        slidesToScroll: 1,
        focusOnSelect: true,
        method: {},
        event: {
            afterChange: function (event, slick, currentSlide) {
                $('.bootstrap-carousel').carousel({
                    interval: false
                }).carousel(currentSlide);
            }
        }
    };

    $timeout(function() {
        if ($(window).width() > 991) {
            angular.element('.submenus-vertical-list li').eq(0).triggerHandler('click');
        }

        $('.bootstrap-carousel').carousel({
            interval: false
        });
    }, 0);

    $scope.changeActiveMenu = function (index) {
        $scope.activeSubmenu = $scope.submenus[index];

        if ($scope.activeSubmenu.sub_menus.length > 0) {
            $scope.superSubmenus = $scope.activeSubmenu.sub_menus;

            if ($(window).width() > 991) {
                $scope.changeActiveSuperSubMenu(0);
            }
        } else {
            $scope.superSubmenus = false;
            $scope.activeSuperSubmenu = false;
            $scope.changeGalleryItems($scope.activeSubmenu);
        }

        if ($(window).width() < 992) {
            $scope.mobileToggle = true;
        }
    };

    $scope.changeActiveSuperSubMenu = function (index) {
        if ($scope.activeSuperSubmenu != $scope.superSubmenus[index]) {
            $scope.activeSuperSubmenu = $scope.superSubmenus[index];
            $scope.changeGalleryItems($scope.activeSuperSubmenu);

            if ($(window).width() < 992) {
                $scope.isSecond = true;
            }
        }
    };

    $scope.changeGalleryItems = function (items) {
        if (typeof items.spot_modules.file_manager !== 'undefined') {
            if (typeof $lg !== 'undefined') {
                $lg.data('lightGallery').destroy(true);
            }

            $scope.galleryItems = items.spot_modules.file_manager[0];
            $scope.animateGallery = true;

            $timeout(function () {
                $scope.animateGallery = false;
            }, 1000);
        }
    };

    $scope.carouselNavigate = function (direction) {
        $('.bootstrap-carousel').carousel({
            interval: false
        }).carousel(direction);

        if ($scope.activeSuperSubmenu) {
            switch (direction) {
                case 'prev':
                    $scope.navigationCarouselConfig.method.slickPrev();
                    break;
                case 'next':
                default:
                    $scope.navigationCarouselConfig.method.slickNext();
            }
        }
    };

    $scope.getQualifiedImageLink = function (imageName) {
        return '/image/' + imageName + '?mark=zoomable-gallery-logo.png&markh=70&markx=10&marky=10';
    };

    $scope.toggleMobile = function (target) {
        if (target == 'gallery') {
            if ($scope.isSecond) {
                $scope.isSecond = false;
            } else {
                $scope.mobileToggle = false;
            }
        } else {
            $scope.mobileToggle = false;
        }
    };
}]);
legoModule.controller('PagesListController', ['$scope', '$document', '$filter', function ($scope, $document, $filter) {
    $scope.pages = pagesData || false;
    $scope.currentPageIndex = false;
    $scope.currentPage = false;
    $scope.refreshing = false;

    var monthsInTurkish = {January: 'Ocak', February: 'Şubat', March: 'Mart', April: 'Nisan', May: 'Mayıs', June: 'Haziran', July: 'Temmuz', August: 'Ağustos', September: 'Eylül', October: 'Ekim', November: 'Kasım', December: 'Aralık'};

    $scope.pagesListCarousel = {
        rows: 2,
        slidesPerRow: 4,
        infinite: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesPerRow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesPerRow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesPerRow: 1
                }
            }
        ]
    };

    $scope.showPageDetail = function (index) {
        if (index > -1 && index < $scope.pages.length) {
            $scope.currentPageIndex = index;
            $scope.currentPage = $scope.pages[index];
            $('.scrollable').data('jsp').scrollToY(0);
        }
    };

    $(function () {
        $('.p-carousel').on('click', '.page', function () {
            $scope.showPageDetail($(this).data('index'));
            $scope.$apply();
        });
    });

    $scope.closePageDetailPanel = function () {
        $scope.currentPage = false;
        $scope.currentPageIndex = false;
    };

    $scope.checkIfOutsideandClosePanel = function ($event) {
        if($($event.target).is('.cd-panel')) {
            $scope.closePageDetailPanel();
        }
    };

    $scope.formatDate = function (dateToFormat) {
        var timeLocale = locale || 'tr';

        var webkitSafe = dateToFormat.replace(/ /g,'T');

        if (timeLocale == 'tr') {
            return $filter('date')(Date.parse(webkitSafe), 'dd') + ' ' + monthsInTurkish[$filter('date')(Date.parse(webkitSafe), 'MMMM')] + ' ' + $filter('date')(Date.parse(webkitSafe), 'yyyy');
        } else {
            return $filter('date')(Date.parse(webkitSafe), 'dd MMMM yyyy');
        }
    };

    $scope.pageImage = function (imageName) {
        return '/image/' + imageName + '?w=500&h=350&fit=crop';
    };

    $document.bind('keyup', function (event) {
        if (event.keyCode == 27 && $scope.currentPage) {
            $scope.$apply(function() {
                $scope.closePageDetailPanel();
            });
        }
    });
}]);